import React from "react";

import styles from "./styles/Home.module.css";

export function Home() {
  return (
    <section id="home">
      <div className={styles.container} role="article">
        <h5>Hi, my name is Ilona</h5>
        <h2>I'm a Web Developer & UI Designer.</h2>
        <div className={styles.text}>
          <p>
            With years of experience in web development, UI/UX design, and
            responsive web design, I can collaborate with you to build{" "}
            <b className={styles.shine}>
              visually appealing and accessible web applications
            </b>
            .
          </p>
          <p>
            These are the skills that allow me to turn ideas into{" "}
            <b className={styles.shine}>polished, functional, and intuitive</b>{" "}
            digital products.
          </p>
          <div>
            <ul className={styles.skills}>
              <li>JavaScript</li>
              <li>TypeScript</li>
              <li>Python</li>
              <li>React JS</li>
              <li>Next JS</li>
              <li>Django</li>
              <li>CSS</li>
              <li>HTML</li>
              <li>Tailwind CSS</li>
              <li>SQL</li>
              <li>jQuery</li>
              <li>Bootstrap</li>
              <li>Canva</li>
              <li>Material UI</li>
              <li>Adobe Creative Suite</li>
              <li>Git / Github</li>
              <li>Jira</li>
              <li>Wireframing with Figma</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
