import React, { useState } from "react";

import styles from "./styles/post.module.css";
import { ButtonExpandable } from "../components/Details";

export default function WCAG() {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className={styles.container}>
      <p>
        Creating web content that is accessible to all users, including those
        with disabilities, is not only a legal and ethical responsibility but
        also a crucial aspect of fostering an inclusive digital environment.{" "}
        <i>Web Content Accessibility Guidelines</i> (WCAG) provide comprehensive
        guidelines to help designers and developers create a more inclusive and
        accessible web experience for all users, regardless of their abilities
        or disabilities.
      </p>
      <p>
        In this article, we will explore the main ideas from WCAG2, and delve
        deeper into the key principles and guidelines for designing accessible
        web content while providing equal opportunities for users with
        impairments to their:
      </p>
      <ul>
        <li>vision - blindness, partial sightedness or colour blindness</li>
        <li>hearing - deafness or hearing loss</li>
        <li>mobility - difficulty using a mouse or keyboard</li>
        <li>
          thinking and understanding - dyslexia, autism or learning difficulties
        </li>
      </ul>

      <p>
        We need to make sure the website can be navigated with ease by users who
        might:
      </p>
      <ul>
        <li>use a keyboard instead of a mouse</li>
        <li>change browser settings to make content easier to read</li>
        <li>
          use a screen reader to <i>read</i> content out loud
        </li>
        <li>use voice commands to navigate a website</li>
      </ul>

      {showMore && (
        <>
          <h5>Understanding WCAG2 Levels of Conformance</h5>

          <p>
            WCAG2 defines three levels of conformance: <i>A, AA,</i> and{" "}
            <i>AAA</i>. These levels indicate the degree to which the guidelines
            have been implemented and the level of accessibility achieved.
          </p>

          <p>
            Conformance level <i>A</i> defines a “bare minimum” conformace
            level, whereas the level <i>AA</i> is widely considered the standard
            level, ensuring a reasonable level of accessibility. Conformance{" "}
            level <i>AAA</i> represents the highest level, although it may not
            always be achievable due to certain constraints.
          </p>

          <p>
            It is important to aim for at least <i>AA</i> conformance to provide
            an inclusive experience to a broader audience.
          </p>

          <h5>Main ideas and principles from WCAG2</h5>

          <p>
            Here are the main ideas and principles from the WCAG2 accessibility
            guide:
          </p>

          <ul>
            <li>
              <h6>Perceivable</h6>
              <p>
                Web content should be presented in a way that can be perceived
                by all users, including those with visual, auditory, and
                cognitive disabilities. This involves:
              </p>

              <ul>
                <li>
                  Text Alternatives: Providing alternative text for non-text
                  content, such as images and videos, enables screen readers to
                  describe the content to visually impaired users.
                </li>
                <li>
                  Adaptability: Designing web content to be adaptable and usable
                  in different ways, such as through resizable text, allows
                  individuals with different disabilities to access and interact
                  with the content effectively.
                </li>
                <li>
                  Distinguishable Content: Sufficient color contrast, text size,
                  and other visual cues helps users with visual impairments
                  navigate and understand the information presented.
                </li>
              </ul>
            </li>

            <li>
              <h6>Operable</h6>
              <p>
                Users should be able to navigate and interact with web content
                using various input methods, including keyboard-only navigation,
                screen readers, and other assistive technologies. The content
                should be designed in a way that avoids causing seizures,
                provides enough time to read and use the content, and allows
                users to easily navigate and find information. Here are some
                important considerations:
              </p>

              <ul>
                <li>
                  Keyboard Accessibility: Designing websites that can be
                  navigated using a keyboard alone is crucial for individuals
                  who cannot use a mouse or other pointing devices.
                </li>
                <li>
                  Sufficient Time: Providing users with enough time to read and
                  interact with content prevents rushed experiences and ensures
                  individuals with cognitive or motor disabilities can access
                  the information comfortably. Let people play, pause and stop
                  any moving content.
                </li>
                <li>
                  Easy Navigation: Creating clear and consistent navigation
                  structures helps users understand the organization of the
                  content and easily find the information they need.
                </li>
              </ul>
            </li>

            <li>
              <h6>Understandable</h6>
              <p>
                The information and operation of web content should be clear and
                easily understandable by all. Users should be able to comprehend
                instructions, error messages, and content organization, the
                language should be straightforward and consistent. Consider the
                following guidelines:
              </p>

              <ul>
                <li>
                  Readability and Understandability: Using clear and concise
                  language, avoiding jargon, and organizing content in a logical
                  manner improves comprehension for all users, including those
                  with cognitive disabilities.
                </li>
                <li>
                  Predictability: Designing consistent and predictable user
                  interfaces helps users understand how the website functions
                  and reduces confusion.
                </li>
                <li>
                  Input Messages: Providing clear and informative messages when
                  users interact with forms or other input fields helps them
                  understand any errors or required actions.
                </li>
              </ul>
            </li>

            <li>
              <h6>Robust</h6>
              <p>
                Web content should be compatible with a wide range of user
                agents (browsers, assistive technologies, etc.) to ensure that
                it can be accessed and interpreted correctly by different
                technologies. This helps to future-proof the accessibility of
                the content as technology evolves. Consider the following best
                practices:
              </p>

              <ul>
                <li>
                  Correct Markup: Using appropriate HTML markup and semantic
                  elements correctly structures the content and enhances
                  compatibility with assistive technologies.
                </li>
                <li>
                  Keyboard Navigation: Ensuring that all interactive elements
                  can be easily accessed and interacted with using a keyboard
                  facilitates compatibility with assistive technologies and
                  enables individuals with motor disabilities to navigate
                  effectively.
                </li>
                <li>
                  Support for Assistive Technologies: Verifying that your web
                  content works seamlessly with screen readers, magnifiers, and
                  other assistive technologies ensures a smooth user experience
                  for various needs.
                </li>
              </ul>
            </li>
          </ul>

          <h5>15 practical WCAG2 conformance wins</h5>

          <ol>
            <li>
              <ButtonExpandable title="Use highly contrasting colors for text and its background">
                <p>
                  Use tools such as{" "}
                  <a
                    href="https://coolors.co/contrast-checker"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    this
                  </a>{" "}
                  to check color contrast ratio for your text and background.
                </p>
                <p>
                  For conformance level AA, the contrast ratio between text and
                  its background should be at least 4.5 to 1. The ratios become
                  more forgiving with larger and heavier fonts since they’re
                  easier to read at lower contrast. If your type is at least
                  18pt or 14pt bold, the minimum contrast ratio drops to 3 to 1.
                  For AAA, the contrast ratio should be at least 7 to 1.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable
                title="Don’t use color alone to make critical information
                understandable"
              >
                <p>
                  Errors and alert messages should still be clear and visible
                  when your website is viewed in black and white. Use icons,
                  labels, tooltips, thick borders, bold text, underlines,
                  italics, etc. The choices are infinite, but the only rule is
                  to use more than color alone.
                </p>
                <p>
                  For graphs and charts, use other visual cues to communicate
                  information such as shapes, patterned fills, labels, and size.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Design usable focus states">
                <p>
                  The <span className={styles.code}>:focus</span> CSS
                  pseudo-class is generally triggered when the user clicks or
                  taps on an element or selects it with the keyboard's{" "}
                  <span className={styles.code}>Tab</span> key. These focus
                  indicators help people know which element has the keyboard
                  focus and help them follow where they are when navigating the
                  website through the keyboard.
                </p>
                <p>
                  Never remove the default CSS focus outline (visible focus
                  indicator) without replacing it with a focus style that will
                  be seen by people with low vision.
                </p>
                <p>
                  Some elements that should be focusable include links, form
                  fields, widgets, buttons, and menu items.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Create responsive design">
                <p>
                  Make sure your website is responsive - for example to the
                  user’s device height and width, page orientation and font size
                  they like to use. Introduce no unnecessary scrolling.
                </p>
                <p>
                  Every feature should still be usable when the text size is
                  increased by 200% and up.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable
                title="Write useful alternative text for your images and other non-text
                content"
              >
                <p>
                  People with low vision often make use of screen readers to
                  “hear” the web. These tools convert text to speech so that the
                  person can hear the words on a site.
                </p>
                <p>There are two ways that you can present alternative text.</p>
                <ul>
                  <li>
                    Within the <span className={styles.code}>alt</span>{" "}
                    attribute of the image element.
                  </li>
                  <li>Within context or surroundings of the image itself.</li>
                </ul>
                <p>
                  Try to describe what’s happening in the image, and how it
                  matters to the story, rather than just saying something like
                  “picture”.
                </p>
                <p>
                  If the image is purely decorative or if it creates redundancy
                  because the surrounding context already explains the content,
                  then adding an empty 
                  <span className={styles.code}>alt</span> attribute will make
                  screen readers skip it. Not providing any{" "}
                  <span className={styles.code}>alt</span> text will make some
                  screen readers read the file name to the individual - and
                  that’s the worst user experience you can provide.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Use correct markup and components">
                <p>
                  HTML elements communicate to the browser what kind of content
                  they contain and how the browser should render or treat that
                  content. The components and structure of a page are what
                  arranges an accessibility tree, therefore not using markup
                  correctly affects accessibility.
                </p>
                <p>
                  Screen readers navigate web pages by heading structure (
                  <span className={styles.code}>h1</span>,{" "}
                  <span className={styles.code}>h2</span>, etc) hierarchically.
                  The people that use your site can listen to a list of all of
                  the headings, jump the content by types of titles, or navigate
                  directly to top-level headings{" "}
                  <span className={styles.code}>h1</span>. Hence, don’t use HTML
                  tags for a style effect only, and also don’t rely on style
                  only to visually determine headings, navigation bars,
                  sections, etc.
                </p>
                <p>
                  In addition, ARIA <span className={styles.code}>role</span>{" "}
                  and
                  <span className={styles.code}>aria-*</span> attributes can be
                  used (with care) to change the exposed semantics of HTML
                  elements. These ARIA attributes instruct screen reader users
                  on how to interact with a component when using the keyboard.
                  They also provide status updates while the user is interacting
                  with a component. For example, they instruct people
                  interacting with a menu to use the arrow keys to move up and
                  down the list. Some rules and tips for using ARIA attributes
                  are listed{" "}
                  <a
                    href="https://www.w3.org/TR/html-aria/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>{" "}
                  and they include:
                </p>
                <ul>
                  <li>
                    Communicate a pressed state with{" "}
                    <span className={styles.code}>aria-pressed=true</span>
                  </li>
                  <li>
                    Communicate a disabled state with{" "}
                    <span className={styles.code}>aria-disabled=true</span>
                  </li>
                  <li>
                    Don’t override interactive elements with non-interactive
                    roles (e.g.{" "}
                    <span className={styles.code}>button role="heading"</span>)
                  </li>
                  <li>
                    Don’t specify redundant roles such as{" "}
                    <span className={styles.code}>button role="button"</span>
                  </li>
                  <li>
                    Adhere to the{" "}
                    <a
                      href="https://www.w3.org/TR/html-aria/#:~:text=Rules%20of%20ARIA%20attribute%20usage%20by%20HTML%20element"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      rules of HTML
                    </a>
                    .
                  </li>
                </ul>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Support keyboard navigation">
                <p>
                  Keyboard accessibility is one of the most critical aspects of
                  web accessibility. People with motor disabilities, blind
                  people that rely on screen readers, people that don’t have
                  precise muscle control, and even power users are dependent on
                  a keyboard to navigate content.
                </p>
                <p>
                  As you navigate through a page, the order of the interactive
                  elements is essential, and the navigation must be logical and
                  intuitive. The tab order should follow the visual flow of the
                  page: left to right, top to bottom — header, main navigation,
                  content buttons and inputs, and finally the footer.
                </p>
                <p>
                  A good practice is testing your site only using a keyboard.
                  Use the <span className={styles.code}>Tab</span> key to move
                  through links and forms, use the{" "}
                  <span className={styles.code}>Enter</span> key to select an
                  element. Verify that all the interactive components are
                  predictable and in order. Keep in mind that tabbing through
                  long menus may be demanding for people with motor
                  disabilities, and listening to lengthy links can be cumbersome
                  for people that use screen readers, so try to be concise.
                </p>
                <p>
                  Providing ARIA landmarks or HTML5 structural elements like{" "}
                  <span className={styles.code}>main</span> or{" "}
                  <span className={styles.code}>nav</span> will make navigation
                  easier.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Don’t use forms without clear field containers or labels">
                <p>
                  Clearly defined boundaries for form fields are important for
                  users with mobility impairments and those with cognitive
                  disabilities. Visual cues for the location and size of the
                  click target are important for people using a standard or
                  adaptive pointing device.
                </p>
                <p>
                  Using placeholder text as labels are one of the biggest
                  mistakes when designing a form. People who use screen readers
                  usually navigate through a form using the{" "}
                  <span className={styles.code}>Tab</span> key to jump through
                  the form controls. The{" "}
                  <span className={styles.code}>label</span> elements are read
                  for each form control. Any non-label text, such as placeholder
                  text, is usually skipped over.
                </p>
                <p>
                  Also, have you ever been in a situation where you’re filling
                  in a form and you forget what that field was asking for and
                  the label is nowhere to be found?{" "}
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Don’t make people hover to find things">
                <p>
                  Instead of hiding actions and information behind hover states,
                  use more inclusive alternatives such as:
                </p>
                <ul>
                  <li>
                    Place secondary actions inside of menus or non-modal
                    dialogs.
                  </li>
                  <li>
                    Play with contrast - lighten up secondary actions and darken
                    them on hover.
                  </li>
                  <li>
                    Use tangible items as triggers for larger hovers, such as an
                    info icon.
                  </li>
                </ul>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable
                title="Provide an alternative/transcript to video-only and audio-only
                content"
              >
                <p>
                  Transcripts are used by people with hearing impairments, or
                  people who have difficulty processing auditory information.
                  Providing transcripts and captions of the speech and
                  non-speech audio information helps people understand the
                  content through text.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Integrate autocomplete">
                <p>
                  Valid autocomplete attributes, such as{" "}
                  <span className={styles.code}>autocomplete="given-name”</span>{" "}
                  or{" "}
                  <span className={styles.code}>
                    autocomplete="family-name”
                  </span>
                  , enable browsers to suggest a form field’s value based on
                  previous user input, making it faster and easier for everyone
                  to fill out forms.
                </p>
                <p>
                  Where possible, make it easy for people to re-enter
                  information they’ve previously entered into a form.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Remove content that flashes, flickers, or blinks">
                <p>
                  People who have photosensitive seizure disorders can have a
                  seizure triggered by content that flashes at certain
                  frequencies for more than a few flashes. No content on your
                  website should flash more than 3 times per second.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Assign language to page">
                <p>
                  Make it clear what language the content is written in, and
                  indicate if this changes.
                </p>
                <p>
                  Always use a language attribute on the html tag to declare the
                  default language of the text on the page. This is inherited by
                  all other elements, so simply use{" "}
                  <span className={styles.code}>&lt;html lang="en"&gt;</span> or
                  add the <span className={styles.code}>lang</span> attribute to
                  any other element surrounding content in a different language.
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Implement clear form input error identifications">
                <p>
                  Ensure that users are aware that an error has occurred, can
                  easily determine what is wrong and correct it. Make sure
                  required fields are visibly marked, value-restricted fields
                  are given option lists or range values, and any error messages
                  are as specific and clear as possible.
                </p>
                <p>
                  You can use ARIA{" "}
                  <span className={styles.code}>role="alert”</span> - this
                  technique notifies Assistive Technologies, such as screen
                  readers, when an input error occurs, allowing it to not be
                  missed.{" "}
                </p>
              </ButtonExpandable>
            </li>
            <li>
              <ButtonExpandable title="Implement error prevention">
                <p>
                  Make sure at least one of the following is true, especially
                  for legal and financial data:
                </p>
                <ul>
                  <li>Form submission is reversible.</li>
                  <li>
                    Data is checked for input errors and the user is provided an
                    opportunity to correct them.
                  </li>
                  <li>
                    There is a mechanism for reviewing, confirming and
                    correcting all information before the submission of the form
                    is finalised.
                  </li>
                </ul>
              </ButtonExpandable>
            </li>
          </ol>

          <h5>The Future of Accessibility Standards with WCAG3</h5>

          <p>
            WCAG3 is still under development and is not yet finalized or
            officially released. However, WCAG3 is expected to build upon the
            foundation established by WCAG2 and address new challenges,
            advancements in technology, and emerging new technologies.
          </p>
          <p>
            WCAG3 will incorporate more user-centric and inclusive design
            approaches, encompassing a wider range of disabilities and focusing
            on a broader concept of accessibility. WCAG3 is expected to be much
            easier to understand, will use a more flexible outcome-based model,
            with these outcomes rated on a scale of 0 to 4. In addition, the new
            guidelines will replace the A/AA/AAA grading with Bronze, Silver,
            and Gold conformance levels.
          </p>
          <p>
            WCAG3 will not deprecate earlier standards, so if your website
            follows WCAG2 Level AA - you’re good to go!
          </p>

          <h5>Conclusion</h5>

          <p>
            WCAG asists in designing and developing accessible web content that
            enhances the user experience for all individuals. By prioritizing
            perceivability, operability, understandability, and robustness of
            the web content, we can ensure that it is accessible to individuals
            with disabilities, providing equal opportunities for all users to
            engage with your service.
          </p>
        </>
      )}

      <div className={styles.center}>
        <button
          onClick={() => setShowMore(!showMore)}
          className={styles.showMoreBtn}
        >
          {showMore ? "Show less" : "Read more"}
        </button>
      </div>
    </div>
  );
}
