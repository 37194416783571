import React, { useState } from "react";
import { CopyBlock, nord } from "react-code-blocks";

import styles from "./styles/post.module.css";
import {
  htmlText,
  code1,
  code2,
  code3,
  code4,
  htmlUL,
  code5,
  code7,
  code6,
  code8,
  code9,
  code10,
  code11,
  code12,
  code13,
  code14,
  code15,
  code16,
  code17,
  code18,
  htmlAbbr,
  code20,
  code21,
  code22,
  code23,
  code24,
  code25,
  finalCSS,
} from "./vars";

export default function CSSCalendar() {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className={styles.container}>
      <p>
        Creating a calendar using CSS is an excellent way to enhance your web
        design skills while understanding the power of layout techniques. In
        this post, I'll walk you through the process of styling a calendar from
        a basic HTML template, turning it into a visually appealing and
        functional component. You'll learn how to use CSS Grid for layout,
        manage spacing, and apply custom styles to various calendar elements
        that react to mouse hovers, as well as the changes in the viewport size.
      </p>
      <p>
        Whether you're a beginner looking to grasp the fundamentals or an
        experienced backend developer aiming to refine your CSS skills, this
        guide will help you craft a clean, responsive calendar design.
      </p>
      <p>
        By the end of this tutorial, we will transform this basic HTML template:
      </p>
      <div>
        <img src={require("./images/1.png")} alt="Starter HTML template" />
      </div>
      <p>To this responsive design:</p>
      <div className={styles.inlineImages}>
        <img src={require("./images/2.png")} alt="Final calendar - mobile" />
        <img
          src={require("./images/3.png")}
          alt="Final calendar - large screen"
        />
      </div>
      <p>
        Now, there are many different ways to achieve this, but I will show you
        my way of thinking about the problem at hand, and my incremental design
        process - step by step. So let’s get started!
      </p>
      {showMore && (
        <>
          <h5>Setup</h5>
          <p>
            Whether you are using an online CSS editor or are setting up this
            small project yourself, you will need two files to get started:
          </p>
          <ol>
            <li>
              <p>
                <span className={styles.code}>index.html</span>
              </p>
              <CopyBlock
                text={htmlText}
                language="html"
                theme={nord}
                codeBlock
              />
            </li>
            <li>
              <p>
                and an empty <span className={styles.code}>styles.css</span>{" "}
                file (located in the same directory for simplicity; otherwise
                you can edit the href attribute of this element on line 7{" "}
                <span className={styles.code}>
                  &lt;link rel="stylesheet" href="styles.css"/&gt;
                </span>{" "}
                to point to the correct location)
              </p>
            </li>
          </ol>
          <p>
            Now open the <span className={styles.code}>index.html</span> file on
            your browser and you are ready to go!
          </p>
          <h5>Step 1: Base Styles</h5>
          <p>
            Let’s start by defining the overall look of the page before we
            tackle any specific element. This means setting the foundation for
            the page such as the background and text colour, font, and making
            sure we have a clean starting point for adding styles on top.
          </p>
          <p>
            I will target the HTML <span className={styles.code}>body</span>{" "}
            element through a CSS <i>Type</i> selector to apply the style to the
            whole page. I want the calendar to have a dark look so I am picking
            a dark colour for the background of the page. I also need to make
            sure the text has enough contrast from the background and can be
            easily read, so I will set the text colour to white.
          </p>
          <CopyBlock text={code1} language="makefile" theme={nord} codeBlock />
          <p>
            You can also change the typeface by setting the{" "}
            <span className={styles.code}>font-family</span> property.
          </p>
          <CopyBlock
            text={code2}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="4"
          />
          <div>
            <img src={require("./images/4.png")} alt="Step 1 final calendar" />
          </div>
          <p>Now let’s start styling our smaller HTML elements.</p>

          <h5>Step 2: Styling the Header</h5>

          <p>
            I want to make the header of the calendar stand out from the rest of
            the elements. I will start by applying a different{" "}
            <span className={styles.code}>background-color</span>
            to the header element, and I also by centering the text on the
            header horizontally with the{" "}
            <span className={styles.code}>text-align</span> property.
          </p>
          <CopyBlock text={code3} language="makefile" theme={nord} codeBlock />
          <p>
            Now that we see the full size of the header because of the different
            background colour, the padding inside the element looks too small,
            and the added margin from the edges of the browser window should be
            removed.
          </p>
          <p>
            First, we can add some padding to the{" "}
            <span className={styles.code}>header</span> element to make it
            larger. Next, we need to figure out where the added margin is coming
            from. By inspecting the DOM elements on the browser, we can see that
            a margin of <span className={styles.code}>8px</span> is added to the{" "}
            <span className={styles.code}>body</span> element. Let’s remove it.
          </p>
          <div>
            <img
              src={require("./images/5.png")}
              alt="Step 2 progress: inspecting DOM"
            />
          </div>
          <p>
            By now, your <span className={styles.code}>styles.css</span> file
            should look something like this:
          </p>
          <CopyBlock
            text={code4}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="5,11"
          />
          <div>
            <img src={require("./images/6.png")} alt="Step 2 final calendar" />
          </div>
          <p>The header element looks good - now let’s style the weekdays.</p>

          <h5>Step 3: Styling the Weekdays</h5>
          <p>We will focus on the unordered list element here:</p>
          <CopyBlock text={htmlUL} language="html" theme={nord} codeBlock />
          <p>
            We want to display this list in a row and there are multiple ways to
            achieve this, but I will be using a CSS <i>Grid</i>.
          </p>
          <p>
            To enable the grid layout in CSS, we need to target the container
            element whose child elements we want to arrange and have precise
            control over. In this case, it is the{" "}
            <span className={styles.code}>ul</span> element with a class of
            <span className={styles.code}>.weekdays</span>. Let’s define it’s{" "}
            <span className={styles.code}>display</span> property.
          </p>
          <CopyBlock text={code5} language="makefile" theme={nord} codeBlock />
          <p>
            So far, nothing has changed on the page as we now need to define the
            structure of the grid. There are seven elements in this list for
            each day of the week, so we need 7 equally spaced columns in this
            grid. Therefore, we will set the{" "}
            <span className={styles.code}>grid-template-columns</span> property.
            We will use a fraction unit in CSS -{" "}
            <span className={styles.code}>1fr</span> is for 1 part of the
            available space, which in this case is the width of the screen. To
            equally divide this row, each of the seven columns will have a width
            of <span className={styles.code}>1fr</span>.{" "}
          </p>
          <CopyBlock
            text={code6}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="3"
          />
          <p>
            That works, however, we can use a function in CSS and avoid typing{" "}
            <span className={styles.code}>1fr</span> seven times.{" "}
            <span className={styles.code}>repeat(7, 1fr)</span> will do this for
            us.
          </p>
          <CopyBlock
            text={code7}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="3"
          />
          <p>
            Now that the weekdays are in one line and equally spaced out, we can
            style the text inside this element. Let’s increase the font size and
            make it bold, as well as align the text in the middle of each
            container.
          </p>
          <CopyBlock
            text={code8}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="4-6"
          />
          <p>
            By default, <span className={styles.code}>li</span> elements under
            an unordered list (<span className={styles.code}>ul</span>) are
            prefixed with a small circle, whereas{" "}
            <span className={styles.code}>li</span> elements under an ordered
            list (<span className={styles.code}>ol</span>) are numbered. On this
            page, we want to remove this default behaviour and we can do that by
            targetting the <span className={styles.code}>list-style</span>{" "}
            property on all <span className={styles.code}>li</span>
            elements.
          </p>
          <CopyBlock text={code9} language="makefile" theme={nord} codeBlock />
          <p>
            Similarly, to remove the underline from the{" "}
            <span className={styles.code}>abbr</span> elements, we can target
            their <span className={styles.code}>text-decoration</span> property.
          </p>
          <CopyBlock text={code10} language="makefile" theme={nord} codeBlock />
          <div>
            <img src={require("./images/7.png")} alt="Step 3 final calendar" />
          </div>
          <p>
            Now that the weekdays row is pretty much how I want it to look,
            let’s move on to the day grid.
          </p>
          <h5>Step 4: Styling the Day Grid</h5>
          <p>
            Just like the weekdays grid, there should be 7 columns for the days
            of the month. We can use the same grid structure on the{" "}
            <span className={styles.code}>ol</span> element with the class of{" "}
            <span className={styles.code}>.day-grid</span> to achieve the
            desired effect.
          </p>
          <CopyBlock text={code11} language="makefile" theme={nord} codeBlock />

          <p>
            To style each list element, we can target the{" "}
            <span className={styles.code}>li</span> elements that have a parent
            with a class <span className={styles.code}>.day-grid</span>. For
            this, we can combine these selectors through a{" "}
            <i>descendant combinator</i>. Let’s add some rounded border and
            increase the height of these elements. For the height, I will be
            using a <span className={styles.code}>vh</span> unit -{" "}
            <span className={styles.code}>100vh</span> is full viewport height,
            I will be using 10% of it.
          </p>
          <CopyBlock text={code12} language="makefile" theme={nord} codeBlock />

          <p>
            Now that we can see the shape of each element, we can spot a problem
            - there is some space on the left side that should not be there.
            Let’s inspect the elements on the browser to see what is going on.
          </p>
          <div>
            <img src={require("./images/8.png")} alt="Step 4 inspecting DOM" />
          </div>
          <p>
            We can see that a left padding of{" "}
            <span className={styles.code}>40px</span> is added to list elements,
            both <span className={styles.code}>ol</span> and{" "}
            <span className={styles.code}>ul</span>. Let’s reset the horizontal
            padding to the same value on both sides, left and right.
          </p>
          <CopyBlock
            text={code13}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="4"
          />

          <p>
            Let’s also match the horizontal padding of the{" "}
            <span className={styles.code}>.weekdays</span> class to the same
            value, and slightly increase its vertical padding too to give it
            some more “breathing” space.
          </p>
          <CopyBlock
            text={code14}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="7"
          />

          <p>
            Great - the grids are now nicely aligned. Next, I want to add some
            space between these grid items to separate them slightly. CSS{" "}
            <i>Grids</i> have a <span className={styles.code}>gap</span>{" "}
            property that allows you to evenly space-out grid items. Let’s add
            the same amount to both grid classes, the{" "}
            <span className={styles.code}>.weekdays</span> and the{" "}
            <span className={styles.code}>.day-grid</span>, to keep their
            alignment intact.
          </p>
          <CopyBlock
            text={code15}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="7,14"
          />

          <p>
            I would also like to center the day numbers in their bordered
            containers. Let’s go back and change the{" "}
            <span className={styles.code}>text-align</span> property on our
            <span className={styles.code}>.day-grid li</span> CSS selector
            styles.
          </p>
          <CopyBlock
            text={code16}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="5"
          />

          <p>
            This has centered the numbers horizontally, but I would also like to
            center them vertically. We can change vertical alignment of the
            element’s children with the{" "}
            <span className={styles.code}>align-content</span> property.
          </p>
          <CopyBlock
            text={code17}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="6"
          />

          <p>
            One last thing I would like to change here is the colour of days of
            the previous and next month - these are differentiated through
            adding the <span className={styles.code}>.month-prev</span> and{" "}
            <span className={styles.code}>.month-next</span> classes. Let’s set
            a white background and a dark text for any day in the current month,
            and keep the current styles for the days in other months.
          </p>
          <p>
            I will use the <span className={styles.code}>:not()</span> CSS
            pseudo-class to target the{" "}
            <span className={styles.code}>.day-grid li</span> elements that{" "}
            <i>do not</i> have the{" "}
            <span className={styles.code}>.month-prev</span> or{" "}
            <span className={styles.code}>.month-next</span> classes attached to
            them, and set the styles on this selection.
          </p>
          <CopyBlock text={code18} language="makefile" theme={nord} codeBlock />
          <div>
            <img src={require("./images/9.png")} alt="Step 4 inspecting DOM" />
          </div>
          <p>
            This way was much easier than creating a new CSS class for the days
            in the current month and attaching it to each HTML element, or
            adding these styles to all{" "}
            <span className={styles.code}>.day-grid li</span> elements and
            overwriting them through the{" "}
            <span className={styles.code}>.month-prev</span> and{" "}
            <span className={styles.code}>.month-next</span> classes.
          </p>
          <p>
            I’m happy with the calendar styles - let’s now make this design
            responsive to different screen sizes!{" "}
          </p>

          <h5>Step 5: Making the Design Responsive</h5>
          <p>
            Currently, the calendar responds to the change in the viewport width
            quite nicely, because we used a CSS Grid that uses a responsive{" "}
            <span className={styles.code}>fr</span>
            unit for its column width. The only change I would like to make is
            to show full, not abbreviated name of the weekday when the screen
            size is big enough to accommodate it.{" "}
          </p>
          <p>
            If you have a look at the <span className={styles.code}>abbr</span>{" "}
            HTML element, you can see that the{" "}
            <span className={styles.code}>title</span> attribute has the
            non-abbreviated version of the weekday name.
          </p>
          <CopyBlock text={htmlAbbr} language="html" theme={nord} codeBlock />
          <p>
            Let’s extract this value and show it through the{" "}
            <span className={styles.code}>::before</span>
            pseudo-element.
          </p>
          <CopyBlock text={code20} language="makefile" theme={nord} codeBlock />
          <p>
            We can now see this value appended to the start of each{" "}
            <span className={styles.code}>abbr</span>
            element. We can selectively show and hide the{" "}
            <span className={styles.code}>abbr</span> or
            <span className={styles.code}>abbr::before</span> content depending
            on the size of the screen, using the{" "}
            <span className={styles.code}>@media</span> rule. Let’s move the
            current <span className={styles.code}>abbr::before</span> styles
            into a media query.
          </p>
          <CopyBlock text={code21} language="makefile" theme={nord} codeBlock />
          <p>
            The full name is now appearing on the screen only if the viewport
            width is larger than 1000px. We can now set the
            <span className={styles.code}>visibility</span> property and toggle
            between visible/hidden values to achieve the wanted effect.
          </p>
          <CopyBlock
            text={code22}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="2,6-8,12,17"
          />
          <p>
            There is a small issue with simply setting the{" "}
            <span className={styles.code}>visibility: hidden;</span> property on
            the <span className={styles.code}>abbr</span> element on large
            screens. The <span className={styles.code}>visibility</span>{" "}
            property specifies whether an element is visible or not, but this
            property does not affect the element's layout - the element is still
            there, just not visible. If you inspect the{" "}
            <span className={styles.code}>abbr</span> element, you can see that
            the area taken by this element is still there, messing up the
            alignment of our HTML elements.
          </p>
          <div>
            <img src={require("./images/10.png")} alt="Step 5 inspecting DOM" />
          </div>
          <p>
            We could try using the <span className={styles.code}>display</span>{" "}
            property set to <span className={styles.code}>none</span>. However,
            that would remove the <span className={styles.code}>abbr</span>{" "}
            element together with the{" "}
            <span className={styles.code}>abbr::before</span>
            pseudo-element, and render the page as if these elements do not
            exist.
          </p>
          <p>
            One way to go around this issue is to make sure the element we want
            to remove from the page is so small in size that it does not affect
            the flow of the page. In this case, I will use the{" "}
            <span className={styles.code}>font-size</span>
            property to do just that.
          </p>
          <CopyBlock
            text={code23}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="13,19"
          />
          <div>
            <img src={require("./images/11.png")} alt="Step 5 final calendar" />
          </div>
          <p>
            Perfect! We can stop here, but let’s go ahead and add a small mouse
            hover effect to finish the project.
          </p>

          <h5>Step 6: Add Hover Effect</h5>
          <p>
            I want the day element to change colour, increase the roundness of
            the corners, and scale up slightly when the mouse is over it - we
            can add these interactive effects through the{" "}
            <span className={styles.code}>:hover</span> pseudo-class.
          </p>
          <CopyBlock text={code24} language="makefile" theme={nord} codeBlock />
          <p>
            The styles are applied immediately when the mouse is over the
            element - let’s smooth-out this transition by setting the time a
            transition should take to complete. We can do that through the
            <span className={styles.code}>transition</span> property on the
            element.
          </p>
          <CopyBlock
            text={code25}
            language="makefile"
            theme={nord}
            codeBlock
            highlight="7"
          />
          <div className={styles.inlineImages}>
            <img
              src={require("./images/2.png")}
              alt="Final calendar - mobile"
            />
            <img
              src={require("./images/3.png")}
              alt="Final calendar - large screen"
            />
          </div>
          <p>And we are finally done!</p>

          <h5>Conclusion</h5>
          <p>
            Congratulations on completing the tutorial! We've transformed a
            simple HTML template for a calendar month into a custom, responsive
            design using a variety of CSS techniques. Throughout this process,
            we utilized CSS Grid to effectively structure our calendar's layout,
            we enhanced the user experience by adding interactive hover effects
            and using transitions for smooth animations, and we also made sure
            the calendar looks great across different screen sizes.
          </p>
          <p>
            Keep experimenting with these and other CSS properties, and feel
            free to build on these styles to develop even more polished and
            distinct calendar designs!
          </p>
          <p>
            Here is the final <span className={styles.code}>styles.css</span>{" "}
            file:
          </p>
          <CopyBlock
            text={finalCSS}
            language="makefile"
            theme={nord}
            codeBlock
          />
        </>
      )}

      <div className={styles.center}>
        <button
          onClick={() => setShowMore(!showMore)}
          className={styles.showMoreBtn}
        >
          {showMore ? "Show less" : "Read more"}
        </button>
      </div>
    </div>
  );
}
