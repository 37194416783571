import React, { useState } from "react";

import styles from "./styles/Details.module.css";

export function Button(props) {
  return (
    <a href={props.href} className={styles.btn}>
      {props.text}
    </a>
  );
}

export function IconButton(props) {
  return (
    <button className={styles.iconBtn} onClick={props.onClick}>
      {props.text}
    </button>
  );
}

export function ButtonExpandable(props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button onClick={() => setOpen(!open)} className={styles.listBtn}>
        {props.title}
        <div>
          <svg
            fill="none"
            height="16"
            viewBox="0 0 24 24"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m8.91003 19.9201 6.51997-6.52c.77-.77.77-2.03 0-2.8l-6.51997-6.52002"
              stroke="#80ffdb"
            />
          </svg>
        </div>
      </button>
      {open && <div className={styles.pl}>{props.children}</div>}
    </>
  );
}
