import React, { useState } from "react";

import styles from "./styles/Resume.module.css";

export function Resume() {
	const [openJob, setOpenJob] = useState(0);
	const [openEducation, setOpenEducation] = useState(0);

	const jobs = [
		{
			title: "Software Developer & UI/UX Designer",
			company: "Vistalworks",
			description: (
				<ul>
					<li>
						Creating wireframes for company web application pages
						with Figma
					</li>
					<li>
						Building webpages with React/JavaScript, MaterialUI, CSS
						and HTML
					</li>
					<li>Graphic design with Canva and Adobe Creative Suite</li>
					<li>
						Integrating client-side applications with external APIs
					</li>
				</ul>
			),
			date: "January 2022 - July 2024",
		},
		{
			title: "Software Engineer",
			company: "Millar Callaghan",
			description: (
				<div>
					<p>
						Zero-hour contract role maintaining the company data
						management software. Duties included:
					</p>
					<ul>
						<li>
							Developing new features in a Django web application
						</li>
						<li>
							Maintaining the application environment (Django,
							PostgreSQL, Linux Ubuntu, Apache Web Server)
						</li>
						<li>Improving the application's design and UI/UX.</li>
					</ul>
				</div>
			),
			date: "March 2021 - Present",
		},
		{
			title: "Junior Developer",
			company: "Vistalworks",
			description: (
				<ul>
					<li>
						Building webpages with React/JavaScript, MaterialUI, CSS
						and HTML
					</li>
					<li>
						Developing APIs using Django and Django REST Framework
					</li>
					<li>
						Improving user interface and structure of existing
						application
					</li>
				</ul>
			),
			date: "June 2020 - January 2022",
		},
	];

	const education = [
		{
			school: "University of Glasgow",
			course: "BSc Computer Science",
			description: (
				<div>
					<p>
						Graduated with Distinction. In this degree, I studied a
						variety of topics, including modules on the following
						subjects:
					</p>
					<ul>
						<li>Web Application Development</li>
						<li>Professional Software Development</li>
						<li>Database Technologies</li>
						<li>Text-as-Data/NLP</li>
						<li>Machine Learning</li>
						<li>Human-Computer Interaction</li>
					</ul>
				</div>
			),
			date: "2018 - 2021",
		},
		{
			school: "City of Glasgow College",
			course: "HNC Computing",
			description: (
				<div>
					<p>
						In this degree, I studied a variety of subjects,
						including modules on the following:
					</p>
					<ul>
						<li>Computing Fundamentals</li>
						<li>Introduction to Programming with Java</li>
						<li>Mobile App Development</li>
						<li>Object-Oriented Programming in C#</li>
						<li>Computer Networking</li>
					</ul>
				</div>
			),
			date: "2017 - 2018",
		},
	];

	return (
		<section id="resume" className={styles.container}>
			<div>
				<h2>Experience</h2>
				<div className={styles.grid}>
					<div className={styles.tab}>
						{jobs.map((job, i) => {
							return (
								<button
									className={`${styles.tablinks} ${
										openJob === i && styles.activeTab
									}`}
									key={i}
									onClick={() => setOpenJob(i)}
								>
									{job.company}
								</button>
							);
						})}
					</div>

					<div className={styles.tabcontent} role="article">
						<div className={styles.row}>
							<span>
								<svg
									viewBox="0 0 1024 1066.744"
									xmlns="http://www.w3.org/2000/svg"
									height={20}
									width={20}
									fill="#80ffdb"
								>
									<path d="M22.26 83.663h979.48V341.89H22.26zm934.957 44.522H66.782v169.183h890.435zm-169.182 62.33h-44.522V21.334h44.522zm-507.548 0h-44.522V21.334h44.522zM22.26 297.37h979.48v725.704H22.26zm934.957 44.522H66.782v636.66h890.435zm-489.74 169.182h-84.59V466.55h84.59zm173.636 0h-84.59V466.55h84.59zm169.183 0h-84.59V466.55h84.59zM467.478 680.255h-84.59v-44.522h84.59zm-169.182 0h-84.59v-44.522h84.59zm342.817 0h-84.59v-44.522h84.59zm169.183 0h-84.59v-44.522h84.59zM467.478 853.89h-84.59v-44.522h84.59zm-169.182 0h-84.59v-44.522h84.59zm342.817 0h-84.59v-44.522h84.59z" />
								</svg>
							</span>
							<span>
								<p>{jobs[openJob].date}</p>
							</span>
						</div>
						<h3>{jobs[openJob].title}</h3>
						<h6>@ {jobs[openJob].company}</h6>
						{jobs[openJob].description}
					</div>
				</div>

				<hr />

				<h2 className={styles.right}>Education</h2>
				<div className={styles.grid}>
					<div className={styles.tab}>
						{education.map((education, i) => {
							return (
								<button
									className={`${styles.tablinks} ${
										openEducation === i && styles.activeTab
									}`}
									key={i}
									onClick={() => setOpenEducation(i)}
								>
									{education.school}
								</button>
							);
						})}
					</div>

					<div className={styles.tabcontent} role="article">
						<div className={styles.row}>
							<span>
								<svg
									viewBox="0 0 1024 1066.744"
									xmlns="http://www.w3.org/2000/svg"
									height={20}
									width={20}
									fill="#80ffdb"
								>
									<path d="M22.26 83.663h979.48V341.89H22.26zm934.957 44.522H66.782v169.183h890.435zm-169.182 62.33h-44.522V21.334h44.522zm-507.548 0h-44.522V21.334h44.522zM22.26 297.37h979.48v725.704H22.26zm934.957 44.522H66.782v636.66h890.435zm-489.74 169.182h-84.59V466.55h84.59zm173.636 0h-84.59V466.55h84.59zm169.183 0h-84.59V466.55h84.59zM467.478 680.255h-84.59v-44.522h84.59zm-169.182 0h-84.59v-44.522h84.59zm342.817 0h-84.59v-44.522h84.59zm169.183 0h-84.59v-44.522h84.59zM467.478 853.89h-84.59v-44.522h84.59zm-169.182 0h-84.59v-44.522h84.59zm342.817 0h-84.59v-44.522h84.59z" />
								</svg>
							</span>
							<span>
								<p>{education[openEducation].date}</p>
							</span>
						</div>
						<h3>{education[openEducation].course}</h3>
						<h6>@ {education[openEducation].school}</h6>
						{education[openEducation].description}
					</div>
				</div>
			</div>
		</section>
	);
}
