import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

import styles from "./styles/Nav.module.css";
import { IconButton } from "../components/Details";

export function Nav() {
  const [hide, setHide] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setHide(false);
      } else if (window.scrollY < 50) {
        setHide(false);
      } else {
        setHide(true);
      }

      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <nav className={`${styles.nav} ${hide && styles.hideNav}`}>
      <Link to="home" smooth spy>
        <p className={styles.logo}>IV</p>
      </Link>
      <span className={styles.navIcons}>
        {/* <div className={styles.tooltip}> */}
        <Link
          to="portfolio"
          smooth
          spy
          activeClass={styles.active}
          offset={-80}
          title="Portfolio"
        >
          <IconButton
            text={
              <svg
                viewBox="0 0 1024 1063.274"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
              >
                <path d="M316.93 815.837l-145.49-150.45L316.7 514.94l33.314 32.202L235.8 665.435l114.214 118.293zm389.862.324l-32.76-32.758 117.97-117.97-117.97-117.968 32.76-32.76L857.566 665.48zm-261.143-3.706l-38.737-25.39L582.985 518.32l38.736 25.392zM0 21.334h1024v1024H0zm977.665 46.334H46.335v931.33h931.33zM1024 336.41H0V21.333h1024zM46.335 290.075h931.33V67.668H46.335zm199.24-88.036H157.54v-46.336h88.035zm180.706 0h-92.67v-46.336h92.67zm176.073 0h-88.036v-46.336h88.036z" />
              </svg>
            }
          />
        </Link>
        {/* <span className={styles.tooltipText}>Portfolio</span>
				</div> */}
        {/* <div className={styles.tooltip}> */}
        <Link
          to="resume"
          smooth
          spy
          activeClass={styles.active}
          offset={-100}
          title="Résumé"
        >
          <IconButton
            text={
              <svg
                viewBox="0 0 1024.001 1066.744"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
              >
                <path d="M111.71 244.75h800.58c61.696 0 111.71 50.015 111.71 111.71v535.273c0 61.695-50.014 111.71-111.71 111.71H111.71C50.013 1003.442 0 953.427 0 891.732V356.46c0-61.695 50.014-111.71 111.71-111.71zm800.58 712.147c35.99 0 65.165-29.175 65.165-65.164V356.46c0-35.99-29.175-65.164-65.164-65.164H111.71c-35.99 0-65.165 29.175-65.165 65.164v535.273c0 35.99 29.175 65.164 65.164 65.164zM823.856 603.15h-246.69v-4.654h-377.02C89.61 598.496.002 508.888.002 398.35v-46.544c0-61.695 50.015-111.71 111.71-111.71h800.582c61.695 0 111.71 50.015 111.71 111.71v51.2c0 110.537-89.61 200.145-200.146 200.145zM623.71 556.607h200.144c84.83 0 153.6-68.77 153.6-153.6v-51.2c0-35.99-29.175-65.164-65.164-65.164H111.708c-35.99 0-65.164 29.175-65.164 65.164v46.545c0 84.832 68.77 153.6 153.6 153.6h423.564zm-88.437 88.436h-46.545V510.06h46.545zM718.29 291.297H305.663L358.586 67.88h306.78zM364.543 244.75H659.41l-30.86-130.326H395.82z" />
              </svg>
            }
          />
        </Link>
        <Link to="blog" smooth spy activeClass={styles.active} title="Articles">
          <IconButton
            text={
              <svg
                viewBox="0 0 1024 1066.697"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
              >
                <path d="M0 114.424h378.88c86.33.106 156.287 70.062 156.393 156.383v622.416h-46.545v-.745c-.023-30.144-12.257-57.426-32.022-77.17v-.002c-19.915-19.962-47.41-32.354-77.8-32.49H.002zm488.727 666.903v-510.51c-.08-60.636-49.212-109.77-109.84-109.848H46.544v575.3H378.88c42.797.12 81.56 17.293 109.87 45.08l-.024-.023zM645.12 114.424H1024v668.393H645.12c-30.39.082-57.886 12.41-77.826 32.305l.002-.002c-19.766 19.746-32 47.028-32.023 77.168v.843h-46.545V270.82c.106-86.33 70.062-156.287 156.383-156.393h.01zm332.335 46.545H645.12c-60.635.078-109.768 49.21-109.847 109.84v510.518c28.286-27.763 67.05-44.937 109.824-45.056h332.358zM1024 960.62H0V759.544h46.545v154.53h930.91v-154.53h46.544z" />
              </svg>
            }
          />
        </Link>
        {/* <span className={styles.tooltipText}>Résumé</span>
				</div>
				<div className={styles.tooltip}> */}
        <Link
          to="contact"
          smooth
          spy
          activeClass={styles.active}
          title="Contact Me"
        >
          <IconButton
            text={
              <svg
                viewBox="0 0 1055.276 1045.334"
                xmlns="http://www.w3.org/2000/svg"
                width={26}
                height={27}
              >
                <path d="M161.17 204.567h549.702c88.967.11 161.06 72.202 161.17 161.16v366.478c-.11 88.967-72.203 161.06-161.16 161.17h-67.644v151.96l-304.495-151.96H161.17C72.2 893.264.11 821.17 0 732.214v-366.48c.11-88.966 72.202-161.06 161.16-161.168h.01zm549.702 640.84c62.487-.083 113.12-50.716 113.202-113.195V365.736c-.082-62.487-50.715-113.12-113.194-113.202H161.17c-62.487.082-113.12 50.715-113.202 113.194v366.476c.082 62.487 50.715 113.12 113.194 113.202H350.16l245.207 122.412V845.406zM896.17 710.138H848.2V662.17h47.967c61.16-1.483 110.277-50.987 111.138-112.16V182.503c0-62.52-50.68-113.202-113.2-113.202H344.404c-62.52 0-113.202 50.683-113.202 113.203v47.967h-47.967v-47.967c0-89.01 72.158-161.17 161.17-161.17h549.7c89.012 0 161.17 72.16 161.17 161.17V549.93c-.53 87.814-71.208 158.945-158.795 160.21h-.118zM298.835 572.953H207.22v-47.967h91.616zm183.234 0h-91.617v-47.967h91.617zm182.754 0h-91.617v-47.967h91.617z" />
              </svg>
            }
          />
        </Link>
        {/* <span className={styles.tooltipText}>Contact</span>
				</div> */}
      </span>
    </nav>
  );
}
