import React, { useState } from "react";

import styles from "./styles/Blog.module.css";
import WCAG from "../blog/WCAG";
import CSSCalendar from "../blog/CSSCalendar";

export function Blog() {
  const [openPost, setOpenPost] = useState(0);

  const posts = [
    {
      title: "Creating Accessible Web Content: A Guide to WCAG2",
      component: <WCAG />,
      date: "June 2023",
    },
    {
      title: "CSS Calendar Challenge",
      component: <CSSCalendar />,
      date: "August 2024",
    },
  ];

  return (
    <section id="blog" className={styles.section}>
      <div className={styles.container}>
        <h2>Articles</h2>
        <div className={styles.postList}>
          {posts.map((post, i) => {
            return (
              <button
                className={`${styles.postBtn} ${
                  openPost === i && styles.activeBtn
                }`}
                key={i}
                onClick={() => setOpenPost(i)}
              >
                {post.title}
              </button>
            );
          })}
        </div>

        <hr />

        <div className={styles.postContainer} role="article">
          <h5>{posts[openPost].date}</h5>
          <h3>{posts[openPost].title}</h3>

          {posts[openPost].component}
        </div>
        <hr />
      </div>
    </section>
  );
}
