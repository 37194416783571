import "./App.css";
import { Nav } from "./layout/Nav";
import { Home } from "./containers/Home";
import { Portfolio } from "./containers/Portfolio";
import { Resume } from "./containers/Resume";
import { Contact } from "./containers/Contact";
import { Blog } from "./containers/Blog"

function App() {
	return (
		<>
			<Nav />
			<Home />
			<Portfolio />
			<Resume />
			<Blog />
			<Contact />
		</>
	);
}

export default App;
