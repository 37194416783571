import React from "react";

import styles from "./styles/Contact.module.css";
import { Button } from "../components/Details";

export function Contact() {
  return (
    <section id="contact">
      <div className={styles.container}>
        <h2>Get in touch</h2>
        <p className={styles.p}>
          If you would like to discuss any projects or potential work,
          <br />
          feel free to drop a message!
        </p>
        <Button text="Say Hi!" href="mailto:ilona.valentukeviciute.com" />
      </div>
    </section>
  );
}
