import React, { useState } from "react";

import styles from "./styles/Portfolio.module.css";

export function Portfolio(props) {
  const [active, setActive] = useState(null);

  return (
    <section id="portfolio">
      <div className={styles.container}>
        <div className={styles.grid}>
          <h2>Some projects I've worked on</h2>
          <div className={styles.contentGrid}>
            <div className={styles.content} role="article">
              <h3>Bugbytes</h3>
              <h5>Youtube Channel & Blog</h5>
              <div className={styles.description}>
                <p>
                  I created the website for the BugBytes education platform,
                  implementing a front-end using React/NextJS. This was
                  integrated with a Django API, and used NextJS server-side
                  rendering to produce static files that were deployed on Amazon
                  S3.
                </p>
                <p>
                  I am also responsible for video editing using Adobe Premiere
                  Pro. This involves editing raw footage into high-quality
                  videos that flow smoothly, and adding custom graphics, sound
                  and video effects.
                </p>
                <div className={styles.tags}>
                  <h6>NextJS</h6>
                  <h6>ReactJS</h6>
                  <h6>CSS</h6>
                  <h6>Python</h6>
                  <h6>Adobe Premiere Pro</h6>
                  <h6>Adobe Photoshop</h6>
                  <h6>Figma</h6>
                </div>
              </div>
              <div className={styles.links}>
                <a
                  href="https://www.youtube.com/@bugbytes3923"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    viewBox="0 0 1024.001 1068.42"
                    xmlns="http://www.w3.org/2000/svg"
                    height={30}
                    width={30}
                    fill="#80ffdb"
                  >
                    <path d="M201.077 114.424h621.847c110.998.132 200.944 90.078 201.076 201.064v444.057c-.132 110.998-90.078 200.944-201.064 201.076h-621.86C90.078 960.49.132 870.544 0 759.558V315.5c.132-110.998 90.078-200.944 201.064-201.076h.013zm621.848 799.65c85.302-.105 154.425-69.227 154.53-154.52V315.5c-.105-85.302-69.227-154.425-154.52-154.53H201.078c-85.302.105-154.425 69.227-154.53 154.52v444.054c.105 85.302 69.227 154.425 154.52 154.53h.01zm-512-592.01L741.843 537.52l-430.918 215.46zm326.75 215.458L357.47 397.374V677.67z" />
                  </svg>
                </a>
                <a
                  href="https://www.bugbytes.io/posts/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    viewBox="0 0 1024.193 1063.18"
                    xmlns="http://www.w3.org/2000/svg"
                    height={30}
                    width={30}
                    fill="#80ffdb"
                  >
                    <path d="M817.02 616.328l-32.854-32.947L910.74 457.27c41.27-41.28 66.796-98.304 66.796-161.29 0-125.992-102.138-228.13-228.13-228.13-63.01 0-120.052 25.544-161.337 66.84L462.005 261.22l-32.947-32.855L555.17 101.79c49.705-49.717 118.38-80.47 194.236-80.47 151.69 0 274.66 122.97 274.66 274.66 0 75.833-30.734 144.49-80.424 194.19zm-542.27 428.865h-.194C122.923 1045.193 0 922.27 0 770.637c0-75.865 30.77-144.542 80.51-194.235l.002-.002 126.62-126.156 32.855 32.947L113.412 609.3c-41.27 41.282-66.795 98.306-66.795 161.29 0 125.994 102.138 228.132 228.13 228.132 63.01 0 120.052-25.544 161.337-66.84l126.063-126.53 32.947 32.855-126.11 126.575c-49.43 49.673-117.846 80.413-193.443 80.413h-.836.043zm31.83-273.44l-32.9-32.9L717.62 294.912l32.9 32.9z" />
                  </svg>
                </a>
              </div>
            </div>
            <div className={styles.images}>
              <div
                className={`${styles.bugbytes1} ${
                  active === 1 && styles.active
                }`}
                onMouseOver={() => setActive(1)}
                onMouseOut={() => setActive(null)}
              />
              <div
                className={`${styles.bugbytes2} ${
                  active === 2 && styles.active
                }`}
                onMouseOver={() => setActive(2)}
                onMouseOut={() => setActive(null)}
              />
              <div
                className={`${styles.bugbytes3} ${
                  active === 3 && styles.active
                }`}
                onMouseOver={() => setActive(3)}
                onMouseOut={() => setActive(null)}
              />
            </div>
          </div>
          <div className={styles.contentGrid}>
            <div className={styles.content} role="article">
              <h3>Vistalworks Intelligence Service</h3>
              <h5>Internal & Client Apps</h5>
              <div className={styles.description}>
                <p>
                  Developed internal and client-facing applications using a
                  range of technologies, mostly focused around React,
                  TypeScript, MaterialUI, Python, and Django API
                  integrations.&lrm;
                </p>
                <p>
                  I was also responsible for creating designs from the
                  ground-up, using Figma for wireframes and Canva + Adobe
                  Creative Suite for graphics and logos.&lrm;
                </p>
                <div className={styles.tags}>
                  <h6>ReactJS</h6>
                  <h6>TypeScript</h6>
                  <h6>Django</h6>
                  <h6>Python</h6>
                  <h6>CSS</h6>
                  <h6>Material UI</h6>
                  <h6>Figma</h6>
                  <h6>Canva</h6>
                  <h6>Tailwind CSS</h6>
                </div>
              </div>
            </div>
            <div className={styles.images}>
              <div className={styles.vsw} />
            </div>
          </div>
          <div className={styles.contentGrid}>
            <div className={styles.content} role="article">
              <h3>Photo Effects</h3>
              <h5>Personal Project - Image Editing Website</h5>
              <div className={styles.description}>
                <p>
                  I built a project that allowed users to upload images, and
                  apply various image processing techniques to those images -
                  such as cropping, resizing, and blurring.
                </p>

                <p>
                  This was built using FastAPI and Python on the backend, to
                  take advantage of Python imaging libraries such as Pillow and
                  OpenCV. The front-end was built using React, allowing users to
                  upload images and apply the above processing techniques.
                </p>
                <div className={styles.tags}>
                  <h6>ReactJS</h6>
                  <h6>Python</h6>
                  <h6>CSS</h6>
                  <h6>FastAPI</h6>
                  <h6>Image Manipulation</h6>
                </div>
              </div>
            </div>
            <div className={styles.images}>
              <div
                className={`${styles.photo1} ${active === 4 && styles.active}`}
                onMouseOver={() => setActive(4)}
                onMouseOut={() => setActive(null)}
              />
              <div
                className={`${styles.photo2} ${active === 5 && styles.active}`}
                onMouseOver={() => setActive(5)}
                onMouseOut={() => setActive(null)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
