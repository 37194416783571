export const htmlText = `<!DOCTYPE html>
  <html lang="en">
  
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="styles.css">
  </head>
  
  <body>
  
    <div class="calendar">
      <header>
        <h1>May 2024</h1>
      </header>
  
      <ul class="weekdays">
        <li>
          <abbr title="Sunday">S</abbr>
        </li>
        <li>
          <abbr title="Monday">M</abbr>
        </li>
        <li>
          <abbr title="Tuesday">T</abbr>
        </li>
        <li>
          <abbr title="Wednesday">W</abbr>
        </li>
        <li>
          <abbr title="Thursday">T</abbr>
        </li>
        <li>
          <abbr title="Friday">F</abbr>
        </li>
        <li>
          <abbr title="Saturday">S</abbr>
        </li>
      </ul>
  
      <ol class="day-grid">
        <li class="month-prev">29</li>
        <li class="month-prev">30</li>
        <li class="month-prev">31</li>
        <li>1</li>
        <li>2</li>
        <li>3</li>
        <li>4</li>
        <li>5</li>
        <li>6</li>
        <li>7</li>
        <li>8</li>
        <li>9</li>
        <li>10</li>
        <li>11</li>
        <li>12</li>
        <li>13</li>
        <li>14</li>
        <li>15</li>
        <li>16</li>
        <li>17</li>
        <li>18</li>
        <li>19</li>
        <li>20</li>
        <li>21</li>
        <li>22</li>
        <li>23</li>
        <li>24</li>
        <li>25</li>
        <li>26</li>
        <li>27</li>
        <li>28</li>
        <li>29</li>
        <li>30</li>
        <li>31</li>
        <li class="month-next">1</li>
      </ol>
  
    </div>
  
  </body>
  
  </html>`;

export const code1 = `body {
    background-color: darkslategray;
    color: whitesmoke;
  }`;

export const code2 = `body {
    background-color: darkslategray;
    color: whitesmoke;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }`;

export const code3 = `header {
    text-align: center;
    background-color: black;
}`;

export const code4 = `body {
    background-color: darkslategray;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: whitesmoke;
    margin: 0;
}

header {
    text-align: center;
    background-color: black;
    padding: 1rem;
}`;

export const htmlUL = `<ul class="weekdays">
    <li>
        <abbr title="Sunday">S</abbr>
    </li>
    ...
</ul>`;

export const code5 = `.weekdays {
    display: grid;
}`;

export const code6 = `.weekdays {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}`;

export const code7 = `.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}`;

export const code8 = `.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    font-size: 1.25rem;
    justify-items: center;
}`;

export const code9 = `li {
    list-style: none;
}`;

export const code10 = `abbr {
    text-decoration: none;
}`;

export const code11 = `.day-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}`;

export const code12 = `.day-grid li {
    border: 1px solid whitesmoke;
    border-radius: 3px;
    height: 10vh;
}`;

export const code13 = `.day-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 0 1rem;
}`;

export const code14 = `.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    font-size: 1.25rem;
    justify-items: center;
    padding: .5rem 1rem;
}`;

export const code15 = `.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    font-size: 1.25rem;
    justify-items: center;
    gap: .5rem;
    padding: .5rem 1rem;
}

.day-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: .5rem;
    padding: 0 1rem;
}`;

export const code16 = `.day-grid li {
    border: 1px solid whitesmoke;
    border-radius: 3px;
    height: 10vh;
    text-align: center;
}`;

export const code17 = `.day-grid li {
    border: 1px solid whitesmoke;
    border-radius: 3px;
    height: 10vh;
    text-align: center;
    align-content: center;
}`;

export const code18 = `.day-grid li:not(.month-prev, .month-next) {
    background-color: whitesmoke;
    color: darkslategray;
}`;

export const htmlAbbr = `<abbr title="Sunday">S</abbr>`;

export const code20 = `abbr::before {
    content: attr(title);
  }`;

export const code21 = `@media screen and (min-width: 1000px) {
    abbr::before {
        content: attr(title);
    }
}`;

export const code22 = `abbr {
    visibility: visible;
    text-decoration: none;
}

abbr::before {
    visibility: hidden;
}

@media screen and (min-width: 1000px) {
    abbr {
      visibility: hidden;
    }

    abbr::before {
        content: attr(title);
        visibility: visible;
    }
}`;

export const code23 = `abbr {
    visibility: visible;
    text-decoration: none;
  }
  
  abbr::before {
    visibility: hidden;
  }
  
  @media screen and (min-width: 1000px) {
    abbr {
      visibility: hidden;
      font-size: 0px;
    }
  
    abbr::before {
      content: attr(title);
      visibility: visible;
      font-size: 1.25rem;
    }
  }`;

export const code24 = `.day-grid li:hover {
    color: whitesmoke;
    border-radius: 6px;
    background-color: black;
    transform: scale(1.05);
}`;

export const code25 = `.day-grid li {
    border: 1px solid whitesmoke;
    border-radius: 3px;
    height: 10vh;
    text-align: center;
    align-content: center;
    transition: all 0.3s ease-out;
}`;

export const finalCSS = `body {
    background-color: darkslategray;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: whitesmoke;
    margin: 0;
}

header {
    text-align: center;
    background-color: black;
    padding: 1rem;
}

.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    font-size: 1.25rem;
    justify-items: center;
    gap: .5rem;
    padding: .5rem 1rem;
}

li {
    list-style: none;
}

.day-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: .5rem;
    padding: 0 1rem;
}

.day-grid li {
    border: 1px solid whitesmoke;
    border-radius: 3px;
    height: 10vh;
    text-align: center;
    align-content: center;
    transition: all 0.3s ease-out;
}

.day-grid li:not(.month-prev, .month-next) {
    background-color: whitesmoke;
    color: darkslategray;
}

.day-grid li:hover {
    color: whitesmoke;
    border-radius: 6px;
    background-color: black;
    transform: scale(1.05);
}

abbr {
    visibility: visible;
    text-decoration: none;
}

abbr::before {
    visibility: hidden;
}

@media screen and (min-width: 1000px) {
    abbr {
      visibility: hidden;
      font-size: 0px;
    }

    abbr::before {
        content: attr(title);
        visibility: visible;
        font-size: 1.25rem;
    }
}`;
